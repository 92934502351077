import React, { useEffect } from "react";
import About from "../Components/About";
import Banner from "../Components/Banner";
import Brands from "../Components/Brands";
import Courses from "../Components/Courses";
import Feature from "../Components/Feature";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Platform from "../Components/Platform";
import Testimonials from "../Components/Testimonials";
import Topics from "../Components/Topics";

const HomeOne = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Header />
      <Banner />
      <About />
      <Brands section_padding_bottom={"section-padding-bottom"} />
      <Feature />
      <Courses />
      <Platform />
      <Topics />
      <Testimonials />
      <Footer />
    </>
  );
};

export default HomeOne;
