import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeOne from "./Pages/HomeOne";
import ErrorPage from "./Pages/404Page";
import AboutOne from "./Pages/AboutOne";
import ContactUs from "./Pages/ContactUs";

import Thanks from "./Pages/Thanks";
import AllCourses from "./Pages/AllCourses";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/about" element={<AboutOne />} />
          <Route path="/services" element={<AllCourses />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route
            path="/contacts"
            element={<ContactUs />}
          />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
