/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import footerLogo from "../assets/images/logo/Logo-Wide-Version_White-Text.png";
import { Link } from "react-router-dom";

// Validation schema with Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer = () => {
  const updatedDate = new Date().getFullYear();
  const [notification, setNotification] = useState("");

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const templateParams = {
        email: values.email,
      };
      emailjs
        .sendForm(
          "service_hh6u00o", // Replace with your EmailJS service ID
          "template_ksv8mb2", // Replace with your EmailJS template ID
          templateParams,
          "-Gqk2HjV7TKQOomRr" // Replace with your EmailJS user ID
        )
        .then(
          (result) => {
            console.log("success", result);
            formik.resetForm();
            setSubmitting(false);
            setNotification(
              "Subscription successful! Thank you for subscribing."
            );
          },
          (error) => {
            console.log("err", error);
            setSubmitting(false);
            setNotification("There was an error. Please try again later.");
          }
        );
    },
  });

  return (
    <footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat">
      <div className="section-padding container">
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <a href="#" className="mb-10 block">
                <img src={footerLogo} alt="" />
              </a>
              <p>Expert Solutions for Every Assignment</p>
              <ul className="flex space-x-4 pt-8">
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:facebook"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:twitter"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:linkedin"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:instagram"></iconify-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-2xl font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <Link to={"/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to={"/contacts"}>Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-2xl font-bold text-white">Newsletter</h4>
            <div className="mb-8">
              Join over <span className="text-primary underline">8,000</span>{" "}
              students receiving helpful tips, updates, and exclusive offers!
              Subscribe now to stay informed and make your academic life easier.
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
                <div className="flex-none">
                  <span>
                    <img src="assets/images/icon/mail.svg" alt="" />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your mail"
                    className="border-none focus:ring-0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary block w-full text-center"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? 'sending..' : 'Subscribe Now'}
              </button>
            </form>
            {notification && (
              <div
                className={`mt-4 text-center text-sm ${
                  notification.includes("error")
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                {notification}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
        &copy; Copyright {updatedDate} | The Learning Insights | All Rights
        Reserved
      </div>
    </footer>
  );
};

export default Footer;
