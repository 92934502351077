import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Validation schema with custom test function for phone numbers
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  contact: Yup.string()
    .required("Contact number is required")
    .test('is-valid-phone', 'Contact number must be valid', value => {
      if (!value) return false;

      const phoneNumber = parsePhoneNumberFromString(value);

      // Check if the phone number is valid
      return phoneNumber ? phoneNumber.isValid() : false;
    }),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const templateParams = {
        name: values.name,
        email: values.email,
        contact: values.contact,
        message: values.message,
      };
      emailjs
        .send(
          "service_hh6u00o",
          "template_d57qnh8",
          templateParams,
          "-Gqk2HjV7TKQOomRr"
        )
        .then(
          (response) => {
            formik.resetForm();
            navigate("/thanks");
            setSubmitting(false);
          },
          (err) => {
            setSubmitting(false);
          }
        );
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 contact-us-form"
    >
      <div>
        <input
          type="text"
          className="form-control"
          placeholder="Name*"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="error">{formik.errors.name}</div>
        ) : null}
      </div>
      <div>
        <input
          type="email"
          className="form-control"
          placeholder="Email*"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
      </div>
      <div className="md:col-span-2 col-span-1">
        <PhoneInput
          defaultCountry="US" // Change to the default country you prefer
          international
          countryCallingCodeEditable={false} // Optional, if you want to make country code non-editable
          placeholder="Enter phone number*"
          value={formik.values.contact}
          onChange={(value) => formik.setFieldValue("contact", value)}
          onBlur={formik.handleBlur}
          className="form-control"
        />
        {formik.touched.contact && formik.errors.contact ? (
          <div className="error">{formik.errors.contact}</div>
        ) : null}
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          className="form-control"
          placeholder="Your Message*"
          rows="5"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></textarea>
        {formik.touched.message && formik.errors.message ? (
          <div className="error">{formik.errors.message}</div>
        ) : null}
      </div>
      <button className="btn btn-primary mt-[10px]" type="submit" disabled={formik.isSubmitting}>
        {formik.isSubmitting ? 'sending..' : 'Send Message'}
      </button>
    </form>
  );
};

export default ContactForm;
