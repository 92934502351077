import React from "react";
import ManOne from "../assets/images/banner/man1.png";

const Banner = () => {
  return (
    <section
      className={`xl:min-h-screen bg-[url('../images/banner/1.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
    >
      <div className="container relative">
        <div className="max-w-[570px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8">
          <h1>
            World's No 1{" "}
            <span className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom">
              Assignments
            </span>{" "}
            Services
          </h1>
          <div className=" plain-text text-gray leading-[30px]">
            Quick Support When You Need It, The Way You Need It, Only At
            The Learning Insights
          </div>
          <div className="md:flex  rounded-md shadow-e1 items-center py-[10px] relative lg:pr-[10px] lg:pl-[38px] pl-4 pr-4"></div>
        </div>
        <div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  ">
          <img src={ManOne} alt="manOne" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
