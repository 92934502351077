/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import testiLeft from "../assets/images/all-img/testi-left.png";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

const Testimonials = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className=" section-padding bg-[url('../images/all-img/section-bg-16.png')] bg-cover  bg-no-repeat lg:mt-[136px]"
    >
      <div className="container lg:-mt-[250px] xl:pb-[136px] lg:pb-20 pb-10">
        <div
          className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] bg-white shadow-box14 rounded-md
                divide-x-2 divide-[#E4EEED] py-10"
        >
          <div className=" text-center ">
            <h2 className="text-secondary font-bold ">
              {isVisible && (
                <CountUp duration={5} className="counter" end={98} suffix="%" />
              )}
            </h2>
            <span className="block text-black font-semibold">Success Rate</span>
          </div>

          <div className="  text-center">
            <h2 className="text-secondary font-bold ">
              {isVisible && (
                <CountUp duration={5} className="counter" end={10} suffix="+" />
              )}
            </h2>
            <span className="block text-black font-semibold">
              Years of Experience
            </span>
          </div>

          <div className=" text-center">
            <h2 className="text-secondary font-bold ">
              {isVisible && (
                <CountUp duration={5} className="counter" end={30} suffix="+" />
              )}
            </h2>
            <span className="block text-black font-semibold">
              Expert Tutors
            </span>
          </div>

          <div className="  text-center">
            <h2 className="text-secondary font-bold ">
              {isVisible && (
                <CountUp duration={5} className="counter" end={100} suffix="%" />
              )}
            </h2>
            <span className="block text-black font-semibold">
              Confidentiality
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="grid grid-cols-12 lg:gap-10 gap-6 ">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12">
            <img src={testiLeft} alt="" draggable="false" />
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12 flex flex-col justify-center ">
            <div className="mini-title">Testimonial</div>
            <h4 className="column-title ">
              What Says Our
              <span className="shape-bg">Students</span>
              <span> </span>
              About Us
            </h4>
            <div>
              Discover how our expert assignment services have made a difference
              in students' academic journeys. From improved grades to
              stress-free deadlines, our clients share their positive
              experiences and success stories.
            </div>
            {/* <div className="mt-12">
              <Link to={"/"} className="btn btn-primary">
                View All Reviews
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
