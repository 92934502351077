import { Disclosure } from "@headlessui/react";
import React from "react";
import { faq } from "../constant/images";

const Accordion = () => {
  return (
    <div className="section-padding  bg-white bg-[url('../images/all-img/section-bg-15.png')] bg-bottom  bg-cover bg-no-repeat">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
          <div>
            <div className="mini-title">Frequently Asked Question</div>
            <div className="column-title ">
              General <span className="shape-bg">Questions</span>
            </div>
            <ul className="list accrodains space-y-[30px] lg:max-w-[470px]">
              <Disclosure>
                {({ open }) => (
                  <li className={open ? "open" : null}>
                    <Disclosure.Button
                      type="button"
                      className="accrodain-button"
                    >
                      <span>What services do you offer?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      We specialize in helping college students with their
                      academic assignments across various disciplines, including
                      essays, reports, projects, and more. Our team of experts
                      ensures that each assignment is thoroughly researched,
                      written, and formatted to meet the specific requirements
                      of your course or institution, helping you achieve
                      academic success.
                    </Disclosure.Panel>
                  </li>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <li className={open ? "open" : null}>
                    <Disclosure.Button
                      type="button"
                      className="accrodain-button"
                    >
                      <span>How do I submit my assignment for help?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      To submit your assignment for help, simply visit our
                      "Contact Us" page and fill out the form with your
                      assignment details, including the topic, deadline, and any
                      specific instructions. Once submitted, our team will
                      promptly review your request and get in touch with you to
                      discuss further steps. We aim to respond quickly and
                      ensure a smooth, hassle-free process from the moment you
                      reach out. Whether you have additional questions or need a
                      quote, we’re here to assist you every step of the way.
                    </Disclosure.Panel>
                  </li>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <li className={open ? "open" : null}>
                    <Disclosure.Button
                      type="button"
                      className="accrodain-button"
                    >
                      <span>
                      How Long Will My Assignment Take?
                      </span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      The time required for completion depends on the nature of
                      the assignment, its length, and complexity. Typically,
                      smaller assignments can be completed within a few days,
                      while larger projects may take longer. We recommend
                      submitting your work well in advance of your deadline to
                      ensure the highest quality. However, we also offer express
                      services for urgent assignments.
                    </Disclosure.Panel>
                  </li>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <li className={open ? "open" : null}>
                    <Disclosure.Button
                      type="button"
                      className="accrodain-button"
                    >
                      <span>Is the service confidential?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      Yes, we prioritize the confidentiality of our clients. All
                      personal information, communication, and assignment
                      details are securely stored and never shared with third
                      parties. Our process is designed to ensure that your
                      identity remains private, and all submissions are handled
                      with discretion. Your academic integrity is important to
                      us, and we follow strict policies to protect it.
                    </Disclosure.Panel>
                  </li>
                )}
              </Disclosure>
            </ul>
          </div>
          <div>
            <img src={faq} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
