import React from "react";
import Header from "../Components/Header";
import PageBanner from "../Components/PageBanner";
import Footer from "../Components/Footer";
import Course from "../Components/Course";
import { courseData } from "../constant/dummyData";

function AllCourses() {
  return (
    <>
      <Header />
      <PageBanner title={"All Services"} pageTitle="Services" />
      <div className=" section-padding">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
            {courseData.map((course, index) => (
              <Course course={course} key={index} index={index} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AllCourses;
