/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const course = ({ course, index }) => {
  return (
    <a
      className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
      href="#"
      key={Math.random() * 1000 * index}
    >
      <div className="course-thumb h-[248px] rounded-t-[8px]  relative">
        <img
          src={course.img}
          alt="images"
          className=" w-full h-full object-cover rounded-t-[8px]"
        />
        <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white absolute left-6 top-6">
          {course.post}
        </span>
      </div>
      <div className="course-content p-8">
        {/* <div className="text-secondary font-bold text-2xl mb-3">
          <p>Pricing is based on the assignment details or the required number of pages.</p>
        </div> */}
        <h4 className=" text-xl mb-3 font-bold">{course.title}</h4>
      </div>
    </a>
  );
};

export default course;
