import {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  c11,
  c12,
  c13,
  c14,
  c15,
  c16,
  c17,
  c18,
  c19,
  c20,
  c21,
  c22,
  c23,
  c24,
  c25,
  counter1,
  counter2,
  counter3,
  counter4,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 90 + "k",
    sector: "Projects Completed",
  },
  {
    icon: counter2,
    num: 500 ,
    sector: "Programs Supported",
  },
  {
    icon: counter3,
    num: 1500 ,
    sector: "Global Students",
  },
  {
    icon: counter4,
    num: 150 ,
    sector: "Excellence Awards",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: [0, "marketing", "design"],
    post: "Finance assignments",
    title:
      "Expertly crafted finance assignments that simplify complex concepts, ensuring clarity and top grades in every submission.",
    price: "$29.28",
    img: c1,

    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Accounting assignments",
    price: "Free",
    title:
      "Accurate and detailed accounting assignments, designed to help you master the numbers and achieve academic excellence.",
    img: c2,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "marketing", "design"],
    post: "Commerce assignments",
    price: "$72.39",
    title:
      "Comprehensive commerce assignments that cover every aspect, helping you excel in your studies and grasp key business concepts.",
    img: c3,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "marketing"],
    post: "Business management assignment",
    price: "$49.33",
    title:
      "Strategically crafted business management assignments that empower you to understand leadership, operations, and organizational success.",
    img: c4,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "finance", "design"],
    post: "Reflective writing assignments",
    title:
      "Insightful reflective writing assignments that help you articulate personal growth, critical thinking, and deep analysis with clarity.",
    price: "Free",
    img: c5,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Statistics assignments",
    title:
      "Precise and data-driven statistics assignments that simplify complex analyses, ensuring accuracy and academic success.",
    price: "$84",
    img: c6,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Essay writing assignments",
    title:
      "Well-crafted essays that articulate your thoughts with clarity and impact.",
    price: "$84",
    img: c7,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Business operations assignments",
    title:
      "Detailed business operations assignments that enhance your understanding of workflow and efficiency.",
    price: "$84",
    img: c8,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Production and Economics Assignments",
    title:
      "Comprehensive assignments that bridge the gap between production principles and economic theory.",
    price: "$84",
    img: c9,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Nursing Assignments",
    title:
      "Thoroughly researched nursing assignments that reflect practical knowledge and patient care expertise.",
    price: "$84",
    img: c10,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "IT Assignments",
    title:
      "Up-to-date IT assignments covering the latest technologies and trends.",
    price: "$84",
    img: c11,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "IT Labs Assignments",
    title:
      "Hands-on IT lab assignments that reinforce your technical skills and practical knowledge.",
    price: "$84",
    img: c12,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Psychology Assignments",
    title:
      "In-depth psychology assignments that explore human behavior and cognitive processes.",
    price: "$84",
    img: c13,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "MATLAB Assignments",
    title:
      "Accurate MATLAB assignments that solve complex computational problems with precision.",
    price: "$84",
    img: c14,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Marketing Assignments",
    title:
      "Strategic marketing assignments that cover key concepts and real-world applications.",
    price: "$84",
    img: c15,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Dissertations and Final Projects",
    title:
      "Comprehensive dissertations and final projects that demonstrate your academic excellence and research skills.",
    price: "$84",
    img: c16,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Pearson Quizzes",
    title:
      "Expert assistance with Pearson quizzes to help you achieve top scores.",
    price: "$84",
    img: c17,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Law Assignments",
    title:
      "Detailed law assignments that navigate complex legal principles and case studies.",
    price: "$84",
    img: c18,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Programming Assignments",
    title:
      "Efficient programming assignments that deliver clean, error-free code across various languages.",
    price: "$84",
    img: c19,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Project Management Labs and Quizzes",
    title:
      "Interactive project management labs and quizzes that sharpen your planning and leadership skills.",
    price: "$84",
    img: c20,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Child Care Assignments",
    title:
      "Well-structured child care assignments that focus on developmental theories and best practices.",
    price: "$84",
    img: c21,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Architectural Surveys and Diagram-Based Assignments",
    title:
      "Precise architectural surveys and diagram-based assignments that bring your designs to life.",
    price: "$84",
    img: c22,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Business Analysis Tasks",
    title:
      "Insightful business analysis tasks that provide actionable insights and data-driven decisions.",
    price: "$84",
    img: c23,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Career Discovery and Communication Assignments",
    title:
      "Practical assignments that enhance your career exploration and communication skills.",
    price: "$84",
    img: c24,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
  {
    id: [0, "design", "finance"],
    post: "Graphics and Designing Assignments",
    title:
      "Creative and visually stunning graphic design assignments that bring your ideas to life with precision and style.",
    price: "$84",
    img: c25,
    ratings: "4.5",
    time: "4h 30m",
    lesson: "2 Lessons",
  },
];

export const topicsData = [
  { logo: t1, title: "Computer Science", quantity: "5 types of assignment services provided" },
  { logo: t2, title: "Machine Learning", quantity: "15+ types of assignment services provided" },
  { logo: t3, title: "Modern Physics", quantity: "7 types of assignment services provided" },
  { logo: t4, title: "Music Subject", quantity: "2 types of assignment services provided" },
  { logo: t5, title: "Law", quantity: "20 types of assignment services provided" },
  { logo: t6, title: "Finances", quantity: "15+ types of assignment services provided" },
  { logo: t7, title: "Psychology", quantity: "5 types of assignment services provided" },
  { logo: t8, title: "IT Projects", quantity: "10+ types of assignment services provided" },
];