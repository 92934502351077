import React from "react";
import Feature from "../Components/Feature";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Accordion from "../Components/Accordion";
import About from "../Components/AboutPageComponents/About";
import Counter from "../Components/AboutPageComponents/Counter";
import PageBanner from "../Components/PageBanner";
import Testimonials from "../Components/Testimonials";

const AboutOne = () => {
  return (
    <>
      <Header />
      <PageBanner title={"About Us"} pageTitle="About Us"/>
      <About />
      <Feature />
      <Counter />
      <Testimonials />
      <Accordion />
      <Footer />
    </>
  );
};

export default AboutOne;
